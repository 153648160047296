/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

carousel-section {
    background-image: url(./style/images/imagem5.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    overflow: hidden;
}