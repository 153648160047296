@keyframes slide-left {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.animate-slide-left {
    animation: slide-left 0.5s ease-out;
}